<template>
    <div>
        <CRow>
			<CCol sm="12">
				<CCard>
					<CCardHeader>
						<strong>{{ this.$t('pages.orders.orderDetail') }} </strong>
					</CCardHeader>
					<CCardBody v-if="!hasError && orderDetail != null && orderDetail.orderInfo != null">
                        <CForm>
                            <CInput :label="this.$t('pages.orders.orderName')" :value="orderDetail.orderInfo.userName" horizontal ></CInput>                        
                            <!-- <CInput :label="this.$t('common.status')" :value="orderDetail.orderInfo.status" horizontal ></CInput> -->
                            <CInput :label="this.$t('common.amount')" :value="orderDetail.amount" horizontal ></CInput>
                            <CInput :label="this.$t('common.phone')" :value="orderDetail.orderInfo.phone" horizontal ></CInput>
                            <CInput :label="this.$t('common.address')" :value="orderDetail.orderInfo.address" horizontal ></CInput>	
                            <CInput :label="this.$t('pages.orders.orderDate')" :value="formatDate(orderDetail.orderInfo.createdDate)" horizontal ></CInput>												
                            
                            <div role="group" class="form-group form-row">
                                <label class="col-sm-3 col-form-label">{{ this.$t('pages.warehouse.selectAgent') }}</label>
                                <div class="col-sm-9 input-group">
                                    <select class="form-control" v-model="orderDetail.orderInfo.agentId">							
                                        <option v-for="(item) in allAgents" v-bind:key="item.id" v-bind:value="item.id">
                                        {{item.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>	
                        </CForm>
					</CCardBody>
					<CCardBody v-else>
						<small>{{errorMessage}}</small>
					</CCardBody>					
				</CCard>
			</CCol>
		</CRow>	
        <!-- order detail -->
        <CRow>
			<CCol sm="12">
				<CCard>
						<CCardHeader>
							<strong>{{ this.$t('pages.orders.detail') }} </strong>
						</CCardHeader>
						<CCardBody v-if="orderDetail != null && orderDetail.orderItems != null">
							<CDataTable
								:items="orderDetail.orderItems"	
								:fields="fields"	
                                hover
                                striped
                                border
                                small
                                fixed>								
							</CDataTable>														
						</CCardBody>
					</CCard>					
			</CCol>
		</CRow>
        <div class="form-actions">         
            <CButton type="button" color="primary" @click="onAssignToAgent">{{ this.$t('common.save') }}</CButton>
            <CButton type="button" color="secondary" @click="onCancel">{{ this.$t('common.back') }}</CButton>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import i18n from '@/plugins/i18n'

    export default {
        name: 'VerifiedDetail',
        data() {
			return {
                selectedAgentId: 0,
                horizontal: { label:'col-3', input:'col-9' },
				files: null,			
				itemsPerPage: 10,
                warningModal: false,               					
				fields: [                      // Array of column object data
					 //{ key: 'id', label: 'Picture' },
                    { key: 'productName', label: i18n.t('pages.product.productName') },
                    { key: 'price', label: i18n.t('common.price') },
                    { key: 'quantity', label: i18n.t('common.quantity') },
                    { key: 'amount', label: i18n.t('common.amount') },
				]               
            }
        },
        computed: {
            ...mapGetters('orders', ['orderDetail', 'hasError', 'errorMessage']),
            ...mapGetters('agent', ['allAgents'])
        },
        methods: {                      
           onCancel() {				
				this.$router.go(-1);
            },
            formatDate(date) {             
                if(date === undefined)
                    return '';                
                return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');               
            }, 
            async onAssignToAgent() {
                var data = await this.$store.dispatch("orders/assignOrdertoAgent");
                if (data == true) {
                    this.$router.push('/orders/orderverification/');
                }
            },
        },
        mounted() {             
            this.$store.dispatch("orders/getOrdersById", this.$route.params.id);
            this.$store.dispatch("agent/getAllAgentForSupport"); 
        }
    }
</script>